<template>
  <section>
    <div class="content-header">
      <h2>“Access Ain’t Inclusion”</h2>
    </div>
    <div class="content-wrapper">
      <p>For disadvantaged students, getting into college is only half the battle. </p>
      <p>In the video, <a href="https://www.youtube.com/watch?v=j7w2Gv7ueOc" target="_blank">Access Ain’t Inclusion</a>, Anthony Abraham Jack, Assistant Professor at the Harvard Graduate School of Education, reveals how and why disadvantaged students struggle and explains what schools can do differently to help them thrive. </p>
      <p>He urges us to grapple with a simple fact: Access is not inclusion. </p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
